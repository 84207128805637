import { Notification } from '~/types/notifications'
import { useAuthStore } from './auth'
import { LikeObjectType } from '~/types/like'

interface State {
  likeListModal: {
    isVisible: boolean
    objectId?: number
    objectType?: LikeObjectType
  }
  alertModal: {
    isVisible: boolean
    title?: string
    message?: string
  }
  notifications: Notification[]
  settings: {
    distance?: string
    temperature?: string
    twelveHourClock?: boolean
  } | null
  isLoadedFromApp: boolean
}

export const useMainStore = defineStore('main', {
  state: (): State => ({
    likeListModal: {
      isVisible: false,
      objectId: undefined,
      objectType: undefined
    },
    alertModal: {
      isVisible: false,
      title: undefined,
      message: undefined
    },
    notifications: [],
    settings: {
      distance: 'metric',
      temperature: 'celsius',
      twelveHourClock: false
    },
    isLoadedFromApp: false
  }),

  actions: {
    showAlertModal(message: string, title?: string) {
      this.alertModal.isVisible = true
      this.alertModal.message = message
      this.alertModal.title = title
    },

    dismissAlertModal() {
      this.alertModal.isVisible = false
      this.alertModal.message = undefined
      this.alertModal.title = undefined
    },

    showLikeListModal(objectType: LikeObjectType, objectId: number) {
      this.likeListModal.isVisible = true
      this.likeListModal.objectId = objectId
      this.likeListModal.objectType = objectType
    },

    hideLikeListModal() {
      this.likeListModal.isVisible = false
      this.likeListModal.objectId = undefined
      this.likeListModal.objectType = undefined
    },

    acceptTos() {
      const authStore = useAuthStore()

      if (authStore.session_data) {
        authStore.session_data.user.accepted_latest_tos = true
      }
    },

    setNotifications(data: Notification[]) {
      this.notifications = data
    },

    updateSettings(settings: any) {
      const authStore = useAuthStore()

      if (!authStore?.session_data.user) {
        return
      }

      this.settings = { ...this.settings, ...JSON.parse(JSON.stringify(settings)) }
    },

    setIsLoadedFromApp(value: boolean) {
      this.isLoadedFromApp = value
    },

    hideScrollbar() {
      if (!document) return
      document.querySelector('body')?.classList.add('no-scrollbar')

      /* 
        On Android, scrolling upwards is sometimes not possible as soon as we hide the body overflow
        Therefore we scroll down by 1px so that the native client thinks there's something to scroll up to
      */
      window.scrollBy(0, 1)
      document.body.style.minHeight = 'calc(100vh + 1px)'
    },

    unhideScrollbar() {
      if (!document) return
      document.querySelector('body')?.classList.remove('no-scrollbar')

      window.scrollBy(0, -1)
      document.body.style.minHeight = '0px'
    }
  }
})

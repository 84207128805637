import { type UserProfile } from '~/types/user'

import Cookie from 'cookie-universal'
import { type H3Event, setCookie } from 'h3'

import { useMainStore } from './index'

export interface SessionData {
  user: UserProfile
  refresh_token?: string
  token?: string
  fbtoken?: string
}

interface State {
  session_data: SessionData | Record<string, any>

  /* 
    There are various places within the app where the data of the current user is stored
    It's stored in the session_data object (which is stored in a cookie)
    The user's current settings are stored in local storage
    But as far as I can tell there is no place where the latest data of the user is store
    That's why I created this object which should always hold the latest user data retrieved from the server
  */
  latestUserData: UserProfile | null
}

const cookies = Cookie()

const isServer = import.meta.env.SSR

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    session_data: {},

    latestUserData: null
  }),

  getters: {
    currentUser: (state): UserProfile | null => {
      if (state.latestUserData) {
        return state.latestUserData
      } else if (state?.session_data?.user) {
        return state.session_data.user
      } else {
        return null
      }
    },

    isLoggedIn: (state) => {
      return !!state.session_data.user
    },

    isUserPro(state): boolean {
      if (state.latestUserData) {
        return state.latestUserData.account_type !== 'basic'
      } else {
        return state?.session_data?.user && state.session_data.user.account_type !== 'basic'
      }
    }
  },

  actions: {
    login(data: SessionData) {
      this.session_data = data
    },

    logout() {
      const mainStore = useMainStore()

      this.session_data = {}
      this.latestUserData = null

      mainStore.notifications = []
      mainStore.settings = null

      cookies.removeAll()
    },

    /*
     This expects a promise that resolves to the current user's data and sets it in the store
     So whenever you do a call that returns a current representation of the user's data, you can wrap it in this function
     Feel free to call it as often as you please
    */
    async awaitAndSetLatestUserData(promise: Promise<UserProfile>): Promise<UserProfile | null> {
      try {
        const userData = await promise

        this.latestUserData = userData

        return userData
      } catch (err) {
        console.error(err)
      }

      return null
    },

    async loginSuccess({
      userData,
      rememberMe = true,
      event
    }: {
      userData: SessionData
      event: H3Event
      rememberMe: boolean
    }) {
      // Store session data
      try {
        this.login(userData)
        // Only store session cookie if user checked "[ ] remember me"
        if (rememberMe) {
          if (isServer) {
            setCookie(
              event,
              'session_data',
              // As any hack, setCookie expects userData to be a string, but its handled in the encode function
              // setCookie is only used for login with Apple and is a pain to debug.
              userData as any,
              {
                sameSite: true,
                path: '/',
                encode: (value) => {
                  return encodeURIComponent(JSON.stringify(value))
                }
              }
            )
          } else {
            cookies.set('session_data', userData, {
              maxAge: 60 * 60 * 24 * 365,
              path: '/'
            })
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
})
